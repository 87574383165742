import React, { useState } from "react";
import {
  Home,
  HandCoins,
  LogOut,
  Menu,
  Bell,
  MessageSquare,
  ChevronRight,
  Wallet,
  RotateCw,
} from "lucide-react";

const RenderLoanPage = ({ children, title, page = 1, onTabChange }) => {
  const [unreadMessages] = useState(3);
  const [unreadNotifications] = useState(5);
  const [userName] = useState("John Doe");

  const menuItems = [
    { id: 1, label: "Home", icon: Home, path: "/loans/home" },
    {
      id: 2,
      label: "Fund Increment",
      icon: HandCoins,
      path: "/loans/fund-increment",
    },
    {
      id: 3,
      label: "Fund Decrement",
      icon: Wallet,
      path: "/loans/fund-decrement",
    },
    {
      id: 4,
      label: "Pin Renewal",
      icon: RotateCw,
      path: "/loans/pin-renewal",
    },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className="w-64 bg-white shadow-lg">
        <div className="h-full flex flex-col pt-6">
          <div className="p-4">
            <h2 className="text-xl font-bold text-gray-800">
              Unions Dashboard
            </h2>
          </div>

          <nav className="flex-1 overflow-y-auto">
            <div className="px-2 space-y-1">
              {menuItems.map((item) => (
                <button
                  key={item.id}
                  className={`flex items-center w-full px-4 py-2.5 text-sm font-medium rounded-lg transition-colors ${
                    page === item.id
                      ? "bg-blue-50 text-blue-700"
                      : "text-gray-700 hover:bg-gray-50"
                  }`}
                  onClick={() => onTabChange(item.id)}
                >
                  <item.icon className="w-5 h-5 mr-3" />
                  {item.label}
                  {page === item.id && (
                    <ChevronRight className="w-4 h-4 ml-auto" />
                  )}
                </button>
              ))}
            </div>
          </nav>

          <div className="p-2 border-t">
            <button className="flex items-center w-full px-4 py-2.5 text-sm font-medium text-red-600 rounded-lg hover:bg-red-50">
              <LogOut className="w-5 h-5 mr-3" />
              Logout
            </button>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Navigation */}
        <header className="bg-white shadow">
          <div className="flex items-center justify-between px-4 py-2">
            <div className="flex items-center space-x-4">
              <button className="p-1 rounded-lg hover:bg-gray-100">
                <Menu className="w-6 h-6 text-gray-600" />
              </button>
              <h1 className="text-xl font-semibold text-gray-800">{title}</h1>
            </div>

            <div className="flex items-center space-x-4">
              <button className="relative p-2 rounded-full hover:bg-gray-100">
                <MessageSquare className="w-6 h-6 text-gray-600" />
                {unreadMessages > 0 && (
                  <span className="absolute top-0 right-0 w-5 h-5 bg-red-500 text-white text-xs font-bold rounded-full flex items-center justify-center">
                    {unreadMessages}
                  </span>
                )}
              </button>

              <button className="relative p-2 rounded-full hover:bg-gray-100">
                <Bell className="w-6 h-6 text-gray-600" />
                {unreadNotifications > 0 && (
                  <span className="absolute top-0 right-0 w-5 h-5 bg-red-500 text-white text-xs font-bold rounded-full flex items-center justify-center">
                    {unreadNotifications}
                  </span>
                )}
              </button>

              <div className="flex items-center space-x-3 pl-4 border-l">
                <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                  <span className="text-blue-700 font-medium">
                    {userName.charAt(0)}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-700">
                    {userName}
                  </span>
                  <span className="text-xs text-gray-500">Loan Officer</span>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* Main Content Area */}
        <main className="flex-1 overflow-y-auto bg-gray-50 p-6">
          <div className="max-w-7xl mx-auto">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default RenderLoanPage;
