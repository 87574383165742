/* eslint-disable  */
import { Route, Switch, Redirect } from "react-router-dom";
import AdminDashboard from "./AdminModel/AdminDashboard";
import AdminLoanApplicationDetailed from "./AdminModel/pages/AdminLoanApplicationDetailed";
import DuesDeductions from "./AdminModel/pages/DuesDeductions";
import FundDeductions from "./AdminModel/pages/FundDeductions";
import LoanBookings from "./AdminModel/pages/LoanBookings";
import LoanDeduction from "./AdminModel/pages/LoanDeduction";
import UPNMGMembers from "./AdminModel/pages/Members";
import PaidLoans from "./AdminModel/pages/PaidLoans";
import SystemUsers from "./AdminModel/pages/SystemUsers";
import "./App.css";
import Login from "./Auth/Login";
import Logout from "./Auth/LogOut";
import HirePurchaseDashboard from "./HirePurchaseModel/HirePurchaseDashboard";
import ApprovedOrders from "./HirePurchaseModel/pages/ApprovedOrders";
import HirePurchaseMessage from "./HirePurchaseModel/pages/HirePurchaseMessage";
import HirePurchaseReport from "./HirePurchaseModel/pages/HirePurchaseReport";
import MartMemberDetailed from "./HirePurchaseModel/pages/MartMemberDetailed";
import OrderDetailed from "./HirePurchaseModel/pages/OrderDetailed";
import Orders from "./HirePurchaseModel/pages/Orders";
import OrderStatus from "./HirePurchaseModel/pages/OrderStatus";
import ProcessedOrder from "./HirePurchaseModel/pages/ProcessedOrder";
import ProductCategory from "./HirePurchaseModel/pages/ProductCategory";
import ProductDetailed from "./HirePurchaseModel/pages/ProductDetailed";
import Products from "./HirePurchaseModel/pages/Products";
import ProfileHirePurchase from "./HirePurchaseModel/pages/ProfileHirePurchase";
import RejectedOrders from "./HirePurchaseModel/pages/RejectedOrders";
import ShippedOrders from "./HirePurchaseModel/pages/ShippedOrders";
import Vendors from "./HirePurchaseModel/pages/Vendors";
import PrivateLoanAdminRoute from "./HOC/PrivateLoanAdminRoute";
import PrivateMartAdminRoute from "./HOC/PrivateMartAdminRoute";
import PrivateRoute from "./HOC/PrivateRoute";
import LoansDashboard from "./LoanModel/LoansDashboard";
import UnionsDashboard from "./LoanModel/UnionsDashboard";
import BookedLoans from "./LoanModel/pages/BookedLoans";
import FundContribution from "./LoanModel/pages/FundContribution";
import InCompleteLoanApplication from "./LoanModel/pages/InCompleteLoanApplication";
import InitiatedLoans from "./LoanModel/pages/InitiatedLoans";
import LoanApplication from "./LoanModel/pages/LoanApplication";
import LoanCalculator from "./LoanModel/pages/LoanCalculator";
import LoanDetailed from "./LoanModel/pages/LoanDetailed";
import LoansMessages from "./LoanModel/pages/LoansMessages";
import LoansMessagesDetailed from "./LoanModel/pages/LoansMessagesDetailed";
import LoansNotifications from "./LoanModel/pages/LoansNotifications";
import LoanStatus from "./LoanModel/pages/LoanStatus";
import LoansPaid from "./LoanModel/pages/PaidLoans";
import ProfileLoan from "./LoanModel/pages/ProfileLoan";
import RejectedLoans from "./LoanModel/pages/RejectedLoans";
import ReportLoans from "./LoanModel/pages/ReportLoans";
import UpdatedLoanApplicationFromMembers from "./LoanModel/pages/UpdatedLoanApplicationFromMembers";
import LoanArchive from "./AdminModel/pages/LoanArchive";
import HirePurchaseMessageDetailed from "./HirePurchaseModel/pages/HirePurchaseMessageDetailed";
import AdminReport from "./AdminModel/pages/AdminReport";
import RequestedOrders from "./HirePurchaseModel/pages/RequestedOrders";
import MartNotifications from "./HirePurchaseModel/pages/MartNotifications";
import LoanArchieveDetailed from "./AdminModel/pages/LoanArchieveDetailed";
import FundIncrement from "./AdminModel/pages/FundIncrement";
import FundIncrementDetailed from "./AdminModel/pages/FundIncrementDetailed";
import AuthorizedLoans from "./LoanModel/pages/AuthorizedLoans";
import LoansDeduction from "./LoanModel/pages/LoansDeduction";
import ChartPage from "./AdminModel/pages/ChartPage";
function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path={"/"} component={Login} />
        <Route exact path={"/logout"} component={Logout} />
        {/* <Route exact path={'*'} component={NotFound404}/> */}

        <PrivateRoute exact path={"/admin/home"} component={AdminDashboard} />

        <PrivateRoute path={"/admin/loans"} component={LoanDeduction} />
        <PrivateRoute path={"/admin/loans-bookings"} component={LoanBookings} />
        <PrivateRoute
          path={"/admin/loans-detailed"}
          component={AdminLoanApplicationDetailed}
        />
        <PrivateRoute
          path={"/admin/archieveloans-detailed"}
          component={LoanArchieveDetailed}
        />

        <PrivateRoute path={"/admin/loans-paid"} component={PaidLoans} />

        <PrivateRoute path={"/admin/funds"} component={FundDeductions} />
        <PrivateRoute
          path={"/admin/fund-increment"}
          component={FundIncrement}
        />
        <PrivateRoute
          path={"/admin/fund-increment-detailed"}
          component={FundIncrementDetailed}
        />
        <PrivateRoute path={"/admin/dues"} component={DuesDeductions} />
        <PrivateRoute path={"/admin/users"} component={SystemUsers} />
        <PrivateRoute path={"/admin/members"} component={UPNMGMembers} />
        <PrivateRoute path={"/admin/archives"} component={LoanArchive} />
        <PrivateRoute path={"/admin/charts"} component={ChartPage} />
        <PrivateRoute path={"/admin/report"} component={AdminReport} />
        {/* Unions Route */}
        <PrivateRoute exact path={"/admin/union"} component={UnionsDashboard} />

        {/* <PrivateRoute  path={'/messages'} component={Messages}/> */}
        {/* PrivateLoanAdminRoute */}

        <PrivateLoanAdminRoute
          path={"/loans/home"}
          component={LoansDashboard}
        />

        <PrivateLoanAdminRoute
          path={"/loans/messages"}
          component={LoansMessages}
        />
        <PrivateLoanAdminRoute
          path={"/loans/notifications"}
          component={LoansNotifications}
        />
        <PrivateLoanAdminRoute
          path={"/loans/messages-detailed"}
          component={LoansMessagesDetailed}
        />
        <PrivateLoanAdminRoute
          path={"/loans/application"}
          component={LoanApplication}
        />
        <PrivateLoanAdminRoute
          path={"/loans/fund-deduction"}
          component={FundContribution}
        />
        <PrivateLoanAdminRoute
          path={"/loans/loans-deduction"}
          component={LoansDeduction}
        />
        <PrivateLoanAdminRoute path={"/loans/booked"} component={BookedLoans} />
        {/* <PrivateLoanAdminRoute  path={'/loans/booked-detailed'} component={BookedLoansDetailed}/> */}
        <PrivateLoanAdminRoute
          path={"/loans/initiated"}
          component={InitiatedLoans}
        />
        <PrivateLoanAdminRoute
          path={"/loans/authorized"}
          component={AuthorizedLoans}
        />
        <PrivateLoanAdminRoute path={"/loans/status"} component={LoanStatus} />
        <PrivateLoanAdminRoute path={"/loans/paid"} component={LoansPaid} />
        <PrivateLoanAdminRoute
          path={"/loans/rejected"}
          component={RejectedLoans}
        />
        <PrivateLoanAdminRoute
          path={"/loans/updated-loan"}
          component={UpdatedLoanApplicationFromMembers}
        />
        <PrivateLoanAdminRoute
          path={"/loans/incomplete-loan"}
          component={InCompleteLoanApplication}
        />
        <PrivateLoanAdminRoute
          path={"/loans/loan-detailed"}
          component={LoanDetailed}
        />
        <PrivateLoanAdminRoute
          path={"/loans/profile"}
          component={ProfileLoan}
        />
        <PrivateLoanAdminRoute
          path={"/loans/calculator"}
          component={LoanCalculator}
        />
        <PrivateLoanAdminRoute
          path={"/loans/loan-report"}
          component={ReportLoans}
        />

        <PrivateMartAdminRoute
          path={"/mart/home"}
          component={HirePurchaseDashboard}
        />
        <PrivateMartAdminRoute
          path={"/mart/notifications"}
          component={MartNotifications}
        />
        <PrivateMartAdminRoute path={"/mart/product"} component={Products} />
        <PrivateMartAdminRoute path={"/mart/vendors"} component={Vendors} />
        <PrivateMartAdminRoute
          path={"/mart/product-detail"}
          component={ProductDetailed}
        />
        <PrivateMartAdminRoute
          path={"/mart/category"}
          component={ProductCategory}
        />
        <PrivateMartAdminRoute
          path={"/mart/profile"}
          component={ProfileHirePurchase}
        />
        <PrivateMartAdminRoute path={"/mart/order"} component={Orders} />
        <PrivateMartAdminRoute
          path={"/mart/request-order"}
          component={RequestedOrders}
        />
        <PrivateMartAdminRoute
          path={"/mart/order-detailed"}
          component={OrderDetailed}
        />
        <PrivateMartAdminRoute
          path={"/mart/processed-order"}
          component={ProcessedOrder}
        />
        <PrivateMartAdminRoute
          path={"/mart/rejected-order"}
          component={RejectedOrders}
        />
        <PrivateMartAdminRoute
          path={"/mart/shipped-order"}
          component={ShippedOrders}
        />
        <PrivateMartAdminRoute
          path={"/mart/approved-order"}
          component={ApprovedOrders}
        />
        <PrivateMartAdminRoute
          path={"/mart/status-order"}
          component={OrderStatus}
        />
        <PrivateMartAdminRoute
          path={"/mart/report"}
          component={HirePurchaseReport}
        />
        <PrivateMartAdminRoute
          path={"/mart/messages"}
          component={HirePurchaseMessage}
        />
        <PrivateMartAdminRoute
          path={"/mart/messages-detailed"}
          component={HirePurchaseMessageDetailed}
        />
        <PrivateMartAdminRoute
          path={"/mart/member-detailed"}
          component={MartMemberDetailed}
        />

        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
