/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import Loader from "../Components/Loader";
import { authActionCreators } from "../services/Actions";
// import "./Auth.css";
import "./css/auth.css";
import axiosInstance from "../services/Axios/axios";
import { authConstants } from "../services/Constants/authConstants";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state?.auth);
  const { Login, ResetAuthResponse } = bindActionCreators(
    authActionCreators,
    dispatch
  );
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { isLoading, response, authenticated, user, user_type } = auth;

  const handleInputChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleClickOpen = () => { };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (formData !== null) {

      try {
        setLoading(true)
        const response = await axiosInstance.post(`/auth/login`, {
          username: formData?.email,
          password: formData?.password,
        });
    
        if (response) {
          // console.log(response.data)
          const { access_token, user_type } = response.data;
          if (user_type === "SYSTEM_USER") {
            localStorage.setItem("token", access_token);

            //get user data
            const user = await axiosInstance.get(`/users/profile/me`);
            const { password, ...rest } = user.data;
            localStorage.setItem("user", JSON.stringify(rest));
            console.log('user', user)
            setLoading(false)
            if (

              user_type === "SYSTEM_USER" &&
              user?.data?.super_role === "ADMIN"
            ) {
              dispatch({
                type: authConstants.LOGIN_SUCCESS,
                payload: { token: access_token, user: rest, user_type: user_type, role: rest.role },
              });
              history.replace("/admin/home");
            }

            if (

              user_type === "SYSTEM_USER" &&
              user?.data?.super_role === "LOAN_OFFICER"
            ) {

              console.log('token:', access_token)
              dispatch({
                type: authConstants.LOGIN_SUCCESS,
                payload: { token: access_token, user: rest, user_type: user_type, role: rest.role },
              });
              history.replace("/loans/home");
            }
            if (

              user_type === "SYSTEM_USER" &&
              user?.data?.super_role === "MART_OFFICER"
            ) {
              dispatch({
                type: authConstants.LOGIN_SUCCESS,
                payload: { token: access_token, user: rest, user_type: user_type, role: rest.role },
              });
              history.replace("/mart/home");
            }
            if (

              user_type === "SYSTEM_USER" &&
              user?.data?.super_role === "UNION_USER"
            ) {
              dispatch({
                type: authConstants.LOGIN_SUCCESS,
                payload: { token: access_token, user: rest, user_type: user_type, role: rest.role },
              });
              history.replace("/admin/union");
            }


            // dispatch({
            //   type: authConstants.LOADING,
            //   isLoading: false,
            // });
          } else {
            setLoading(false)
            dispatch({
              type: authConstants.RESPONSE,
              response: {
                state: "ERROR",
                message: "Opps make sure you have the right access",
              },
            });
            // dispatch({
            //   type: authConstants.LOADING,
            //   isLoading: false,
            // });
          }
        }
        // setLoading(true)
      } catch (e) {
        setLoading(false)
        // alert('Opps make sure you have the right access')
        dispatch({
          type: authConstants.RESPONSE,
          response: {
            state: "ERROR",
            message: "Opps make sure you have the right access",
          },
        });

      }

      // Login(formData);
    } else {
      toast.error("All fields are required");
    }
  };

 
  //ADMIN, LOAN_OFFICER, MART_OFFICER, INVESTMENT_OFFICER, USER

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetAuthResponse();
      }, 300);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetAuthResponse();
      }, 300);
    }
  }, [response?.state]);


  return (
    <>
      {isLoading || loading && <Loader />}
      <div className="loginPage">
        <form
          className="section_one"
          style={{
            background:
              "url(/images/dev/fund-bg.jpeg) no-repeat center center/cover",
          }}
        >
          <div className="overlay"></div>
        </form>
        <div
          className="section_two"
          style={{
            background:
              "url(/images/dev/background.jpg) no-repeat center center/cover",
          }}
        >
          <div className="overlay">
       
            <form>
              <h1 className="title">Login</h1>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email / Staff code
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="example@gmail.com / 202020"
                  required
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="mb-6" style={{position: 'relative'}}>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your password
                </label>
                <input
                  type={showPassword ? 'text': 'password'}
                  id="password"
                  name="password"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  required
                  onChange={(e) => handleInputChange(e)}
                />
                    <span
                    style={{position: 'absolute', right: '20px', top: '40px'}}
                  className="icon text-gray-600 cursor-pointer"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>

              <button className="button mb2 mt1" onClick={handleLogin}>
                Login
              </button>
              <p
                className="forgot_password"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={handleClickOpen}
              >
                Forgot password?
              </p>
            </form>
          </div>
        </div>

        {/* <AlertDialogSlide open={open} close={handleClose}/> */}
        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
}

export default Login;
