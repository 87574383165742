/* eslint-disable  */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  ArrowUpRight,
  ArrowDownRight,
  Check,
  X,
  FileDown,
  Download,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { dataActionCreators } from "../services/Actions";
import { toast } from "react-toastify";
import RenderUnionPage from "./RenderUnionPage";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { GetFundIncrement, GetFundDecrement, DownloadFundIncrement } = bindActionCreators(
    dataActionCreators,
    dispatch
  );

  const [filter, setFilter] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportType, setExportType] = useState("increment");
  const [activeTab, setActiveTab] = useState(2);

  // Redux state
  const data = useSelector((state) => state.data);
  const { fund_increment, fund_decrement, isLoading } = data;

  // Fetch fund increment data on component mount
  useEffect(() => {
    GetFundIncrement();
    GetFundDecrement()
  }, []);

  const handleAction = (action, row) => {
    console.log(`Action: ${action}`, row);
    if (action === "Approve") {
      // Implement approval logic
      console.log("Approving", row);
    } else if (action === "Mark as Rejected") {
      // Implement rejection logic
      console.log("Rejecting", row);
    } else if (action === "Generate Excel") {
      generateExcel(row);
    }
  };

  const generateExcel = (row) => {
    console.log("Generating Excel for:", row);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  const closeExportModal = () => {
    setIsExportModalOpen(false);
    setStartDate("");
    setEndDate("");
    setExportType("increment");
  };

  const handleExport = () => {
    if (startDate === "" || endDate === "") {
      toast.error("Please select start and end date");
      return;
    }

    const exportData = {
      startDate,
      endDate,
    };

    DownloadFundIncrement(exportData);
    closeExportModal();
  };

  const columns = [
    {
      name: "Member Info",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div>
          <span className="font-medium block">{row.name}</span>
          <span className="text-gray-600 text-sm">{row.staff_id}</span>
        </div>
      ),
    },
    {
      name: "Current Contribution",
      selector: (row) => row.current_contribution,
      sortable: true,
      cell: (row) => (
        <span className="font-medium text-gray-900">
          GHS {row.current_contribution}
        </span>
      ),
    },
    {
      name: "Requested Amount",
      selector: (row) => row.requested_increment,
      sortable: true,
      cell: (row) => (
        <span className="text-gray-600">GHS {row.requested_increment}</span>
      ),
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
      sortable: true,
      cell: (row) => <span className="text-gray-900">{row.phone_number}</span>,
    },
    {
      name: "Facility",
      selector: (row) => row.facility,
      sortable: true,
      cell: (row) => <span className="text-gray-900">{row.facility}</span>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex items-center space-x-2">
          <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => handleAction("Approve", row)}
            type="button"
          >
            <Check size={16} className="text-green-500" />
          </button>

          {/* <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => handleAction("Mark as Rejected", row)}
            type="button"
          >
            <X size={16} className="text-red-500" />
          </button>

          <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => handleAction("Generate Excel", row)}
            type="button"
          >
            <FileDown size={16} className="text-blue-500" />
          </button>*/}
        </div>
      ),
    },
  ];

  const customStyles = {
    table: {
      style: {
        backgroundColor: "white",
        borderRadius: "0.5rem",
      },
    },
    rows: {
      style: {
        fontSize: "14px",
        padding: "16px 8px",
        borderBottom: "1px solid #f1f1f1",
        "&:hover": {
          backgroundColor: "#fafafa",
        },
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        padding: "16px 8px",
        backgroundColor: "#f8fafc",
        color: "#475569",
      },
    },
    cells: {
      style: {
        padding: "16px 8px",
      },
    },
  };

  return (
    <RenderUnionPage
      title={activeTab === 2 ? "Fund Increment" : "Fund Decrement"}
      page={activeTab}
      onTabChange={setActiveTab}
    >
      <div className="p-6">
        <div className="bg-white rounded-lg shadow">
          <main className="p-6">
            <section className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              <div
                className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4"
                style={{
                  background:
                    "url(/images/dev/bg1.jpg) no-repeat center center/cover",
                }}
              >
                <ArrowUpRight className="text-blue-500" size={24} />
                <div>
                  <h3 className="text-lg text-white font-semibold">
                    Total Applications
                  </h3>
                  <p className="text-2xl text-white font-bold">
                    {fund_increment?.length + fund_decrement?.length }
                  </p>
                </div>
              </div>
              <div
                className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4"
                style={{
                  background:
                    "url(/images/dev/bg5.jpg) no-repeat center center/cover",
                }}
              >
                <ArrowUpRight className="text-green-500" size={24} />
                <div>
                  <h3 className="text-lg text-white font-semibold">
                    Total Increments
                  </h3>
                  <p className="text-2xl text-white font-bold">
                    {fund_increment?.length || 0}
                  </p>
                </div>
              </div>
              <div
                className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4"
                style={{
                  background:
                    "url(/images/dev/bg6.jpg) no-repeat center center/cover",
                }}
              >
                <ArrowDownRight className="text-red-500" size={24} />
                <div>
                  <h3 className="text-lg text-white font-semibold">
                    Total Decrement
                  </h3>
                  <p className="text-2xl text-white font-bold">
                  {fund_decrement?.length || 0}
                  </p>
                </div>
              </div>
            </section>

            <section className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={() => setIsExportModalOpen(true)}
                  className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  <Download size={16} className="mr-2" />
                  Export Excel
                </button>
                {/* 
                <select
                  onChange={(e) => setFilter(e.target.value)}
                  className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 text-sm"
                >
                  <option value="All">All Status</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                </select> */}
              </div>

              <DataTable
                columns={columns}
                data={fund_increment || []}
                pagination
                customStyles={customStyles}
                highlightOnHover
                progressPending={isLoading}
              />
            </section>
          </main>
        </div>
      </div>

      {/* Export Excel Modal*/}
      {isExportModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-4">Export Data</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
              <button
                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                onClick={closeExportModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleExport}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      )}
    </RenderUnionPage>
  );
};

export default Dashboard;
