/* eslint-disable  */
import React, { useEffect, useState } from "react";
import RenderAdminPage from "../RenderAdminPage";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { dataActionCreators } from "../../services/Actions";
import { bindActionCreators } from "redux";
import CediSymbol from "../../Components/CediSymbol";
import Loader from "../../Components/Loader";
import { useHistory } from "react-router-dom";
import { SiMicrosoftexcel } from "react-icons/si";
import { toast } from "react-toastify";
function FundIncrement() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { GetFundIncrement, DownloadFundIncrement } = bindActionCreators(
    dataActionCreators,
    dispatch
  );
  const [limit, setLimit] = useState(20);
  const [formData, setFormData] = useState({});

  const [search, setSearch] = useState(null);

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };
  const data = useSelector((state) => state.data);
  // const paginate = data?.paginate
  const { fund_increment, isLoading } = data;

  useEffect(() => {
    GetFundIncrement();
  }, []);

  console.log("fund_increment", fund_increment);

  const onSearchTextChange = debounce((e) => {
    if (e?.target?.value) {
      setSearch(e?.target?.value ?? null);
      console.log("search", search);
      console.log("e?.target?.value", e?.target?.value);
      //   GetAllFunds(
      //     true,
      //     1,
      //     15,
      //     `${e?.target?.value ? "&search=" + e?.target?.value : ""}`
      //   );
    } else {
      setSearch(null);
      //   GetAllFunds(true, 1, 15, null);
    }
  }, 800);

  const hanndleDate = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const downloadExcelData = async (data) => {
    // await axiosInstance.get('/funds/download').then((res) => res.data)
    // fetch('http://localhost:8000/api/v1/funds/download').then(res => {res.blob(); console.log('res.blob', res.blob())} ).then(blob => {
    //   const url = window.URL.createObjectURL(new Blob([blob]))
    //   console.log('blob', blob)
    //   const link = document.createElement('a')
    //   link.href = url
    //   link.setAttribute('download', 'funds.xlsx')
    //   document.body.appendChild(link)
    //   link.click()
    //   document.body.removeChild(link)
    // })

    if (formData.fromDate == "" && formData.endDate == "") {
      toast.error("Please select start and end date");
    } else {
      // console.log('formData', formData)
      DownloadFundIncrement(formData);
    }
  };
  return (
    <div>
      <RenderAdminPage title={""}>
        {isLoading && <Loader />}
        {/* <div className="row container pt-2">
                    <div className="col-md-7">
                        <label htmlFor="search">Search Query:</label>
                        <input
                            type={"search"}
                            onChange={onSearchTextChange}
                            id="search"
                            placeholder="search by staff id, name"
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-2">
                        <label htmlFor="search">List to show:</label>
                        <select className="form-control " onChange={handleLimit}>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={70}>70</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                            <option value={250}>250</option>
                            <option value={350}>350</option>
                            <option value={500}>500</option>
                            <option value={600}>600</option>
                        </select>
                    </div>
                </div> */}

        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold leading-tight">
                Fund Increment
              </h2>
              <div>
                <div className="">
                  <label htmlFor="startDate">Start Date: </label>
                  <input
                    type="date"
                    onChange={hanndleDate}
                    name="startDate"
                    id="startDate"
                  />
                </div>
                <div className="">
                  <label htmlFor="endDate">End Date: </label>
                  <input
                    type="date"
                    onChange={hanndleDate}
                    name="endDate"
                    id="endDate"
                  />
                </div>
                <SiMicrosoftexcel
                  size={30}
                  className="icon cursor-pointer"
                  onClick={downloadExcelData}
                />
              </div>
            </div>
            <div className="-mx-1 sm:-mx-8 px-4 sm:px-8 py-0 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Member Info
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Current Contribution
                        {/* deducted */}
                      </th>
                      <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Requested Amount
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Phone Number
                        {/* Type */}
                      </th>
                      <th className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Facility
                      </th>
                      <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fund_increment?.length > 0 ? (
                      <>
                        {fund_increment?.map((fund, index) => {
                          return (
                            <tr key={index + 1}>
                              <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                                <div className="flex">
                                  {/* <div className="flex-shrink-0 w-10 h-10">
                                                                        {fund?.user?.profile_image ? (
                                                                            <img
                                                                                className="w-full h-full rounded-full"
                                                                                src={fund?.user?.profile_image}
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                {fund?.user?.name?.split(' ')[0] === "Mr." ? (
                                                                                    <img
                                                                                        className="w-full h-full rounded-full"
                                                                                        src="/images/dev/avarta2.jpeg"
                                                                                        alt=""
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        className="w-full h-full rounded-full"
                                                                                        src="/images/dev/woman-avarta.jpeg"
                                                                                        alt=""
                                                                                    />
                                                                                )}
                                                                            </>

                                                                        )}
                                                                    </div> */}
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {fund?.name}
                                    </p>
                                    <p className="font-bold text-gray-600 whitespace-no-wrap">
                                      {fund?.staff_id}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  <CediSymbol />
                                  {fund?.current_contribution}
                                </p>
                                {/* <p className="text-gray-600 whitespace-no-wrap">USD</p> */}
                              </td>
                              <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {fund?.requested_increment}
                                </p>
                                {/* <p className="text-gray-600 whitespace-no-wrap">Due in 3 days</p> */}
                              </td>
                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {fund?.phone_number}
                                </p>
                              </td>
                              <td className="px-1 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {fund?.facility}
                                </p>
                              </td>
                              <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm text-right">
                                <button
                                  type="button"
                                  onClick={() =>
                                    history.push(
                                      "/admin/fund-increment-detailed",
                                      { state: fund }
                                    )
                                  }
                                  className="inline-block text-gray-500 hover:text-gray-700"
                                >
                                  <svg
                                    className="inline-block h-6 w-6 fill-current"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <>Data is not available</>
                    )}
                  </tbody>
                </table>
              </div>

              {/* {funds.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              )} */}
            </div>
          </div>
        </div>
      </RenderAdminPage>
    </div>
  );
}

export default FundIncrement;
